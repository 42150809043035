@import (reference) 'vars';

.details-tabs {
    div:not(.active) {
        cursor: pointer;
    }
}

.form-group-contents {
    padding: 0 30px 30px 30px;

    .form-sub-group-contents {
        padding-top: 30px;
    }
}

label {
    .boldFont();
    color: #212121;
    font-size: 14px;
}

.header-responsive {
  @media only screen and (min-width: 768px) {
    display: none;
  }

  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  background: @purple-color;
  padding: 11px 15px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

  .search-container {
    &.header-bar {
      position: relative;
      margin: 30px 0 15px 0;
    }
  }

}



@import (reference) "vars";

.upload-image {
    .images-list {
        margin-top: 2rem;
        padding: 5px;
        border-collapse: collapse;

        li {
            list-style: none;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border: 1px solid #ddd;
            padding: 5px 10px;

            &:first-child {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

            }

            &:last-child {
                border-bottom-left-radius: 5px;
                border-bottom-right-radius: 5px;
            }
        }

        span {
            font-weight: 600;
            overflow: hidden;
            white-space: nowrap;

            text-overflow: ellipsis;
        }

        button {
            padding: 5px 10px;
        }
    }

    .form-controls {
        display: flex;
        gap: 2rem;
        align-items: center;

        label {
            margin: 0;
            padding: .5rem;
            background-color: #ddd;
            border-radius: .5rem;
        }

    }

    .container-actions {
        margin-top: 2rem;
    }

    input[type=file]::file-selector-button {
        display: none;
    }

    input[type=file] {
        padding: .2em .4em;
        border-radius: .2em;
        transition: 1s;
        color: @black-color;
    }

}
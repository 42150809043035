@import (reference) 'vars';

.hospital-info-view {
    padding: 20px;
    background-color: @white-color;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    margin: 30px auto;
    .map-container {
        height: 200px;
        background: url(../images/map-placeholder.jpg) center no-repeat;
        border: 1px solid black;
    }
    .info-container {
       .info-header {
           span {
               display: block;
               &.name {
                   .boldFont();
                   font-size: 24px;
               }
           }
       }
        .info-inner {
            margin-top: 30px;
            span {
                display: block;
            }
            .left-container {
                padding-left: 0;
                .boldFont();
            }
            .bold-title {
                line-height: 20px;
                .boldFont();
            }
        }
    }
}

.search-container {
  &__patients {
    margin: 2rem auto;

    input::placeholder {
      color: #aeaeae;
      font-style: italic
    }
  }
  .placeholder(#212121);
  width: 100%;
  height: 36px;
  position: relative;

  @media (min-width: 768px) {
    width: 262px;
  }

  @media (min-width: 1024px) {
    width: 562px;
  }

  input {
    width: calc(100%  - 62px);
    @media (min-width: 768px) {
      width: 260px;
    }

    @media (min-width: 1024px) {
      width: 500px;
    }
    border-radius: 5px 0 0 5px;
    border: 0;
    color: #212121;
    .regularFont();
    font-size: 14px;
    padding: 0 17px 0 17px;
    box-sizing: border-box;
    outline: none;

    &.no-border-radius {
      border-radius: 0;
    }
  }

  button {
    background: url(../images/search-button-icon.svg) #eaeaea center center no-repeat;
    width: 62px;
    border: 0;
    border-radius: 0 5px 5px 0;
    border-left: #c9c9c9;
    background-size: 40%;
    cursor: pointer;
  }

  &.home-bar {
    margin: 0 auto;
    height: 45px;
    position: relative;

    input {
      position: absolute;
      left: 0;
      top: 0;
      line-height: 45px;
    }

    button {
      position: absolute;
      right: 0;
      top: 0;
      height: 45px;
    }

    ul#search-options {
      top: 45px;
    }
  }

  &.header-bar {
    display: flex;
    flex-grow: 2;
    justify-content: flex-start;

    input {
      line-height: 36px;
    }

    button {
      height: 36px;
    }

    ul#search-options {
      top: 100%;
      max-width: 560px;
      border-radius: 0 0 5px 5px;
      border-left: 1px solid rgba(0, 0, 0, 0.8);
    }
  }

  ul#search-options {
    position: absolute;
    z-index: 9999;
    width: 90%;
    background: @white-color;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    margin-bottom: 20px;
    list-style: none;
    padding: 0;
    .regularFont();
    max-height: 400px;
    overflow: auto;

    li.search-options-group {
      ul.option-group {
        list-style: none;
        padding: 0;

        li {
          cursor: pointer;
          padding: 2px 0 2px 10px;
        }

        li:hover {
          background-color: @purple-color;
          color: @white-color;
        }
      }

      span {
        padding-left: 10px;
        display: block;
        background-color: #eaeaea;
        font-size: 18px;
        .boldFont();
      }
    }
  }
}
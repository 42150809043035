@import (reference) 'vars';

.details-tab-contents {
    margin-top: 30px;
    .form-title {
        .boldFont();
        color: @purple-color;
        font-size: 16px;
        line-height: 20px;
    }
    .complete-action {
        color: #333;
        .boldFont();
        &:hover {
            color: #333;
        }
    }
}

.details-tabs {
    button {
        background: none;
        border: none;
    }
    
    &.justified {
        display: -webkit-flex; /* Safari */
        -webkit-justify-content: space-around; /* Safari 6.1+ */
        display: flex;
        justify-content: space-around;
        position: relative;

        & > span {
            padding: 20px 15px;
            vertical-align: middle;
            line-height: 100%;
            z-index: 2;
            cursor: pointer;

            &.active {
                border-bottom: 3px solid #b65f8c;
                font-weight: bold;
                cursor: default;
            }
        }
        &:after {
            border-bottom: 1px solid #b7b7b7;
            position: absolute;
            bottom: 1px;
            width: 100%;
            content: '';
            z-index: 1;
            left: 0;
        }
    }

    div {
        display: inline-block;
        border-bottom: 1px solid #b7b7b7;
        text-align: center;
        vertical-align: middle;
        line-height:100%;

        &.active {
            border-bottom: 3px solid #b65f8c;
            font-weight: bold;
            cursor: default;
            padding: 20px 60px;
        }
        a, button {
            padding: 20px 60px;
            text-decoration: none;
            color: #333333;
            display: block;
        }
        &.clickable-tab {
            padding: 20px 60px;
            color: #333333;
        }
    }
}
.tabs-navigation-actions {
    margin-top: 30px;
    .text-left {
        padding-left: 40px;
        .back-nav {
            cursor: pointer;
            position: relative;
            .boldFont();
            color: @black-color;
            font-size: 14px;
            line-height: 20px;
            border: none;
            &:hover {
                color: @black-color;
            }
            &:focus {
                outline: none;
            }
            &:after {
                content: '';
                background: url(../images/backarrow_icon.svg) left center no-repeat;
                background-size: 30px;
                position: absolute;
                left: -40px;
                top: 0;
                bottom: 0;
                width: 40px;
            }
        }
    }
    .text-right {
        padding-right: 40px;
        .next-nav {
            cursor: pointer;
            position: relative;
            .boldFont();
            color: @black-color;
            font-size: 14px;
            line-height: 20px;
            border: none;
            &:hover {
                color: @black-color;
            }
            &:focus {
                outline: none;
            }
            &:after {
                content: '';
                background: url(../images/backarrow_icon.svg) center no-repeat;
                background-size: 30px;
                -webkit-transform: rotate(180deg);
                transform: rotate(180deg);
                position: absolute;
                right: -40px;
                top: 0;
                bottom: 0;
                width: 40px;
            }
        }

    }
}

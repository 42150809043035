@import (reference) 'vars';
.user-info-view {
    padding: 20px;
    background-color: @white-color;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;

    .avatar-container {
        img {
            width: 100%;
        }
    }

    .user-info-list {
        dd, dt {
            line-height: 35px;
        }

        .user-info-header {
            font-size: 18px;
        }
    }

    .user-info-role {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .user-info-row {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        &:not(:first-child) {
            border-bottom: 1px solid #999;
        }
    }

    .user-info {
        flex: 1;
        padding: 5px 5px 5px 0;
    }
}

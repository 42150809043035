@import (reference) "vars";
//@import "../../node_modules/react-select/dist/react-select.css";

.form-group {
  .react-datepicker-wrapper {
    width: 100%;
    .react-datepicker__input-container {
      width: 100%;
      input {
        cursor: context-menu;
        width: 100%;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        color: #212121;
        .regularFont();
        line-height: 35px;
        font-size: 14px;
        padding: 0 17px 0 17px;
        box-sizing: border-box;
        outline: none;
      }
    }
  }

  .react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name,
  .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
    &:hover {
      background-color: @purple-color;
      color: @white-color;
    }
  }
  .react-datepicker__day--outside-month {
    color: grey;
  }
  .react-datepicker__day--today {
    font-size: 1.1em;
    background-color: #aeaeae;
    color: #000;
  }
  .react-datepicker__day--selected {
    background-color: @purple-color;
    font-weight: bold;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
}

@import (reference) 'vars';

.hospital-switcher {
    position: relative;
    align-self: center;
    margin-right: 15px;
    .selected-state {
        color: @white-color;
        font-size: 16px;
        line-height: 35px;
        .boldFont();
    }
}

.icon__caret {
    margin-left: 10px;
    border-top: 7px dashed;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent;
    vertical-align: middle;
    display: inline-block;
}

.icon__caret--active {
    border-bottom: 7px dashed;
    border-top: 0;
}

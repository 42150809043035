@import (reference) 'vars';

.app-popover {
  position: absolute;
  top: 120%;
  z-index: 1000;
  margin: 5px 0;
  background-color: @white-color;
  box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.8);
  border-radius: 5px;
  text-align: center;

  .menu-item {
    .boldFont();
    cursor: pointer;
    display: block;
    font-size: 16px;
    line-height: 32px;
    padding: 2px 20px;
    color: @purple-color;
    border-bottom: 1px solid #b7b7b7;

    &:hover {
      text-decoration: none;
      color: #fff;
      background-color: @purple-color;
    }

    &:last-child {
      border-bottom: transparent;
    }
  }
}

.app-popover--wide {
  width: 300px;
}

.app-popover--narrow {
  width: 100%;
  right: 0;

  @media (min-width: 768px) {
    width: 200px;
    top: 120%;
  }
}

.app-popover--from-left {
  left: 0;
}

.app-popover--from-right {
  right: 0;

  @media (min-width:  768px) {
    right: 25px;
  }
}

.selected-state {
  cursor: pointer;
}

@import "vars";

.home {
  .top {
    background: url(../images/top-background.png);
    background-repeat: round;
    width: 100%;
    height: auto;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

    @media (min-width: 786px) {
      height: 326px;
    }

    @media (min-width: 640px) {
      height: 376px;
    }

    h1 {
      color: @white-color;
      .regularFont();
      text-align: center;
      font-size: 20px;
      margin: 20px auto;

      @media (min-width: 640px) {
        font-size: 26px;
        margin: 50px auto 40px auto;
      }

      span {
        .boldFont();
      }
    }

    .add-actions-container {
      width: 622px;
      height: 45px;
      margin: 40px auto 0 auto;

      a {
        .boldFont();
        font-size: 16px;
        color: #212121;
        display: inline-block;
        padding: 0 10px 0 40px;
        border-radius: 5px;
        line-height: 40px;
        text-decoration: none;
        min-width: 194px;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
      }

      .add-patient {
        background: url(../images/add-pacient.svg) 12px center #e0e0e0 no-repeat;
        background-size: 10%;
      }

      .add-hospital {
        background: url(../images/add-hospital.svg) 12px center #e0e0e0 no-repeat;
        background-size: 10%;
      }

      .add-user {
        // TODO: Replace this with it's own icon
        background: url(../images/add-hospital.svg) 12px center #e0e0e0 no-repeat;
        background-size: 10%;
      }
    }
  }

  .bottom-section {
    background-color: @white-color;
    padding: 20px 30px 30px 30px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    text-align: center;

    &.patients {
      .image {
        background: url(../images/patients.svg) no-repeat;
      }
    }

    &.hospitals {
      .image {
        background: url(../images/hospitals.svg) no-repeat;
      }
    }

    &.statistics {
      .image {
        background: url(../images/statistics.svg) no-repeat;
      }
    }

    &.patients,
    &.hospitals,
    &.statistics {
      .image {
        background-size: 100%;
        width: 95px;
        height: 95px;
        margin: 0 auto;
      }
    }

    .title {
      .boldFont();
      font-size: 24px;
      color: #212121;
      text-align: center;
      margin: 23px 0 15px 0;
      text-transform: uppercase;
    }

    .description {
      line-height: 20px;
      font-size: 14px;
      .regularFont();
      color: #212121;
      display: block;
      text-align: center;
      margin: 0 0 23px 0;
    }
  }
}

.row-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  .column-margin {
    margin-bottom: 20px;
  }

  .content {
    height: 100%;
    padding: 20px 20px;
    color: #fff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
  }

  .title {
    .boldFont();
    font-size: 24px;
    color: #212121;
    text-align: center;
    margin: 23px 0 15px 0;
    text-transform: uppercase;
  }

  .colour-1 {
    background: #ffff;
    color: black;
  }

  p .btn {
    background-color: #212121;
    color: #fff;
    font-weight: bold;
  }
}

.react-hooks-paginator {
  padding: 2rem;
}

@media only screen and (max-width: 768px) {
  .home {
    .top {
      .add-actions-container {
        width: auto;
        margin: 20px 0;
        text-align: center;
      }
    }
  }
}

@import (reference) 'vars';

.notifications {
    .icon {
        position: relative;
        .bell {
            display: inline-block;
            width: 32px;
            height: 32px;
            background: url(../images/notification-icon.svg) no-repeat;
            background-size: 100%;
        }
        .badge {
            position: absolute;
            background-color: #000;
            min-width: 7px;
            padding: 3px 5px;
            font-size: 11px;
            left: 13px;
            bottom: 5px;
            font-weight: normal;
        }
    }
    #popup {
        cursor: default;
        position: fixed;
        width: 30%;
        padding: 20px;
        background: @white-color;
        left: 35%;
        right: 35%;
        z-index: 99999;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 5px;

        .list-group {
            span {
                cursor: pointer;
                &:hover {
                    background: @purple-color;
                    color: @white-color;
                }
            }
        }

        .list--scrollable {
            max-height: 425px;
            overflow: auto;
        }
    }
    #popup-bg {
        cursor: default;
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9998;
        background: rgba(71, 19, 64, 0.30);
    }
}

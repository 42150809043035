.font-type(@fontName, @family, @weight, @style) {
    @font-face {
        font-family: @fontName;
        src: url('../fonts/@{family}.ttf');
        font-weight: @weight;
        font-style: @style;
    }
}

.font-type("RobotoThin","Roboto-Thin", 100, 'normal');
.font-type("RobotoLight","Roboto-Light", 300, 'normal');
.font-type("RobotoRegular", "Roboto-Regular", 400, "normal");
.font-type("RobotoMedium","Roboto-Medium", 500, 'normal');
.font-type("RobotoBold","Roboto-Bold", 700, 'normal');
.font-type("RobotoBlack","Roboto-Black", 900, 'normal');

.font-type("OpenSansRegular","OpenSans-Regular", 400, 'normal');
.font-type("OpenSansBold","OpenSans-Bold", 700, 'normal');

.thinFont() {
    font-family: "RobotoThin";
}
.lightFont() {
    font-family: "RobotoLight";
}
.regularFont() {
    font-family: "RobotoRegular";
}
.mediumFont() {
    font-family: "RobotoMedium";
}
.boldFont() {
    font-family: "RobotoBold";
}
.openRegularFont() {
    font-family: "OpenSansRegular";
}
.openBoldFont() {
    font-family: "OpenSansBold";
}
.blackFont() {
    font-family: "RobotoBlack";
}
@import (reference) "vars";

.container {
  padding: 0;
  .table.patient-list,
  .table.users-list,
  .table.hospitals-list {
    margin: 30px 0 20px 0;
    border-radius: 5px;
    thead {
      tr {
        td {
          line-height: 25px;
          vertical-align: middle;
          padding: 15px 8px 15px 0;
          &:first-child {
            padding-left: 15px;
          }
          &:last-of-type {
            padding-right: 15px;
            text-align: right;
          }
          .sort-by {
            cursor: pointer;
            color: @purple-color;
            position: relative;
            &::after {
              font-family: "Glyphicons Halflings";
              font-style: normal;
              font-weight: 400;
              position: absolute;
              right: -12px;
              width: 10px;
              height: 10px;
              top: -9px;
              content: "\e253";
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
            &::before {
              font-family: "Glyphicons Halflings";
              font-style: normal;
              font-weight: 400;
              position: absolute;
              right: -12px;
              bottom: 4px;
              width: 10px;
              height: 10px;
              content: "\e252";
              -webkit-font-smoothing: antialiased;
              -moz-osx-font-smoothing: grayscale;
            }
          }
          .sorted[data-direction="asc"] {
            &::before {
              display: none;
            }
          }
          .sorted[data-direction="desc"] {
            &::after {
              display: none;
            }
          }
        }
      }
    }
    tbody {
      tr {
        td {
          border: 0;
          padding: 0;
          vertical-align: middle;
          .title-link {
            padding-left: 15px;
            .boldFont();
            color: @purple-color;
            font-size: 16px;
            line-height: 20px;
            &:hover {
              text-decoration: none;
            }
          }
          div.cell {
            vertical-align: middle;
            background-color: #fff;
            box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
            margin-bottom: 10px;
            min-height: 56px;
            word-wrap: break-word;
            padding-right: 5px;
            line-height: 56px;
            span {
              line-height: 25px;
              display: block;
              .regularFont();
              color: #000;
              &.city-span {
                position: relative;
                top: 50%;
                transform: translate(0, -50%);
              }
            }
            &.name-container {
              padding-left: 15px;
            }
            &.states-container {
              //padding: 18px 0;
              display: flex;
              align-items: flex-start;
              flex-direction: column;
              justify-content: center;
            }
            &:last-of-type {
              padding-right: 0;
            }
          }
          &:first-of-type,
          &:last-of-type,
          &.city {
            position: relative;
            div.cell {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
            }
          }
          &:first-of-type,
          &.city {
            min-width: 200px;
            div.cell {
              z-index: -1;
              overflow: hidden;
            }
          }
          &.city {
            min-width: 80px;
          }
        }
      }
    }
  }
  #myPatients {
    display: none;
    & + label {
      color: #b65f8c;
      cursor: pointer;
      position: relative;
      padding-left: 30px;
      &::after {
        content: "";
        width: 20px;
        height: 20px;
        background: white;
        border: 1px solid #b65f8c;
        border-radius: 2px;
        position: absolute;
        left: 0;
        top: -1px;
      }
    }
    &:checked {
      & + label {
        &::before {
          content: "";
          width: 11px;
          height: 7px;
          position: absolute;
          top: 5px;
          left: 5px;
          border-top: 3px solid #b65f8c;
          border-right: 3px solid #b65f8c;
          background: transparent;
          -webkit-transform: rotate(130deg);
          transform: rotate(130deg);
          z-index: 9;
        }
      }
    }
  }
}

.container {
  padding: 0;
  table.user-table,
  table.patient-table,
  table.hospital-table {
    margin: 30px 0 20px 0;
    border-radius: 5px;
    thead {
      tr {
        th {
          &.sortable {
            cursor: pointer;
            span.glyphicon {
              margin-left: 5px;
              display: inline;
            }
          }
        }
      }
    }
  }
}
tbody {
  tr {
    background-color: #fff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.8);
    td {
      .title-link {
        .boldFont();
        color: @purple-color;
        font-size: 16px;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.mt-10 {
    margin-top: 10px;
  }

.mr-10 {
  margin-right: 10px;

  @media only screen and (max-width: 768px) {
    margin-right: 0;

  }
}

@import (reference) "vars";

.pagination {
  li {
    &.active {
      a,
      a:hover,
      a:focus {
        background: @purple-color;
        border-color: @purple-color;
      }
    }
    a {
      color: @purple-color;
    }
  }
}

.customButton {
  ul {
    list-style: none;
  }
  background: @purple-color;
  border-color: @purple-color;
}

@import (reference) 'vars';

.user-access-history {
    padding: 20px;
    background-color: #fff;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    .actions-header {
        text-align: right;
        padding-right: 10px;
    }
    .actions-container {
        padding: 0;
        .actions-button {
            height: 20px;
            background-position: center center;
            background-size: 20px;
            top: initial;
            transform: initial;
        }
        .menu {
            top: 20px;
            right: -13px;
            width: 165px;
        }
    }
    .glyphicon-ok-circle {
        color: @green-color;
    }
    .glyphicon-ban-circle {
        color: @red-color;
    }
}

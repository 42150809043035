.header {
  display: none;
  justify-content: space-between;
  align-items: center;
  background: @purple-color;
  padding: 15px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  margin: 0 0 4px 0;

  @media only screen and (min-width: 768px) {
    display: flex;
  }

  .right-header-wrapper {
    display: flex;
    flex-grow: 0;
    // justify-content: space-between;
    align-items: center;
  }
}

.top-user {
  display: flex;
  align-items: center;

  .notifications {
    width: 32px;
    height: 32px;
  }
}

.logo-container {
  a {
    display: block;
    width: 60px;
    height: 60px;
    background: url(../images/Logo\ HeROI.png) no-repeat;
    background-size: 100%;
    outline: none;
  }
}

@import (reference) "vars";

.forms-container {
  padding: 0;
  background-color: @white-color;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 30px auto;
  position: relative;
  .inner-form {
    padding: 0;
    &.no-avatar-forms {
      padding: 0 0 55px 0;
    }
    .avatar-container {
      background-color: #f4f4f4;
      min-height: 607px;
      height: 100%;
      text-align: center;
      .avatar-default {
        border-radius: 50%;
        margin: 30px auto 15px auto;
        width: 100px;
        height: 100px;
        display: block;
        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .avatar-hospital {
        background: url(../images/oncoapp_hospital_lightgrey.svg) center center
          no-repeat;
      }
      .add-avatar {
        background: url(../images/plus.svg) 12px center @black-color no-repeat;
        background-size: 10%;
        font-size: 14px;
        padding: 10px 10px 10px 35px;
        cursor: pointer;
      }
    }
    .hospital {
      min-height: 453px;
      &.form-group {
        min-height: inherit;
      }
    }
    .form-group {
      .error {
        display: none;
      }
    }
    .form-group.has-error {
      input.input-fields {
        border: 1px solid #a94442;
      }
      label {
        color: #a94442;
      }
      .Select-control {
        border: 1px solid #a94442;
      }
      &.startDate {
        input {
          border: 1px solid #a94442 !important;
        }
      }
      .error {
        display: block;
      }
    }
    .user-avatar {
      min-height: 356px;
    }
    .fields-container {
      padding: 40px 100px 30px 100px;
      height: 100%;
      .form-label {
        width: 100%;
        .boldFont();
        color: #212121;
        font-size: 14px;
      }
      .input-fields,
      .Select-control {
        width: 100%;
        border-radius: 5px;
        border: 1px solid #e0e0e0;
        color: #212121;
        .regularFont();
        line-height: 35px;
        font-size: 14px;
        padding: 0 17px 0 17px;
        box-sizing: border-box;
        outline: none;
      }
      .Select-control {
        .Select-input {
          vertical-align: initial;
          height: 35px;
          input {
            padding: 0;
            line-height: 35px;
          }
        }
        .Select-arrow-zone {
          width: 10px;
          padding-right: 0;
          .Select-arrow {
            border: none;
            width: 10px;
            height: 10px;
            background: url(../images/dropdown_arrow.svg) center center
              no-repeat;
            background-size: 10px;
            position: relative;
            top: 2px;
          }
        }
      }
      .Select-menu-outer {
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        border: 1px solid #e0e0e0;
        margin-top: 0;
      }
      .is-open > .Select-control .Select-arrow {
        top: 2px;
        -moz-transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -o-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
      }
      .is-focused:not(.is-open) > .Select-control {
        border-color: #e0e0e0;
        box-shadow: none;
      }
    }
  }

  .bottom-actions-container {
    text-align: center;
    position: absolute;
    bottom: 20px;
    left: 40%;
    .back-btn,
    .back-link {
      margin-right: 30px;
    }
    &.centered-actions {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
}

.option-wrap .Select-menu {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.bottom-action-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

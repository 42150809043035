@import (reference) "vars";

.appointments-container {

    .filters-container {
        margin-bottom: -15px;
    }

    .btn-filter {
        margin: 20px 0px;
        background-color: @green-color;
        color: @white-color;
        font-weight: 600;

        &:focus,
        &:hover {
            color: @white-color;
        }
    }

    .actions-container {
        & button {
            padding: 10px 5px;

            &:disabled {
                background-color: #999;
            }
        }
    }

}
@import (reference) 'vars';

.user-notifications-tab {
    .list-group-item {
        cursor: pointer;
    }
    .un-read {
        margin-right:5px;
        color: @purple-color;
        font-size: 16px;
        .boldFont();
    }
}

#popup {
    .list-group {
        .list-group-item {
            &:hover {
                .un-read {
                    color: #ffffff;
                }
            }
            .un-read {
                margin-right:5px;
                color: @purple-color;
                font-size: 16px;
                .boldFont();
            }
        }
    }
}
@import "~bootstrap/less/bootstrap";
@import "fonts";
@import "header";
@import "header-responsive";
@import "search-field";

@icon-font-path: "~bootstrap/fonts/";

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: url(../images/grey-bg.png);
  background-size: cover;
}

.top-bar {
  display: none;
  justify-content: space-between;
  align-items: baseline;
  padding: 11px 15px;

  @media (min-width: 768px) {
    display: flex;
    align-items: center;
  }

  .logo-container {
    padding-right: 20px;
    padding: 0;

    @media only screen and (min-width: 640px) {
      flex-grow: 1;
    }

    a {
      display: block;
      width: 103px;
      height: 36px;
      background: url(../images/Logo\ HeROI.png) no-repeat;
      background-size: auto 100%;
      outline: none;
    }
  }
}

.header-responsive {
  .top-bar-responsive {
    padding: 20px 0;
    width: 100%;
  }
}

.top-bar-responsive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 2%;

  .first-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: 768px) {
    display: none;
  }
}

*:focus {
  outline: none;
}

@green-color: #373;
@red-color: #9b0200;
@black-color: #212121;
@purple-color: #471340;
@white-color: #ffffff;

.top-bar,
.top-bar-responsive {
  .logo-container {
    float: left;

    a {
      display: block;
      width: 60px;
      height: 60px;
      background: url(../images/Logo\ HeROI.png) no-repeat;
      background-size: auto 100%;
      outline: none;
    }
  }
}

.placeholder(@color) {
  ::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: @color;
  }
  ::-moz-placeholder {
    /* Firefox 19+ */
    color: @color;
  }
  :-ms-input-placeholder {
    /* IE 10+ */
    color: @color;
  }
  :-moz-placeholder {
    /* Firefox 18- */
    color: @color;
  }
}

.table {
  margin-bottom: 0;
}

.green-button {
  .boldFont();
  font-size: 14px;
  background-color: @green-color;
  padding: 10px 50px;
  border: none;
  border-radius: 5px;
  color: @white-color;
}

.green-link {
  .boldFont();
  font-size: 14px;
  background-color: @green-color;
  padding: 10px 25px;
  border: none;
  border-radius: 5px;
  color: @white-color;
  float: right;
  text-align: center;

  &:hover {
    color: @white-color;
    text-decoration: none;
  }

  &__internment-form {
    padding: 5px 25px;
  }
}

.black-button {
  background: @black-color;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  .boldFont();
  font-size: 16px;
  color: @white-color;
  display: inline-block;
  padding: 10px 30px 10px 30px;
}

.back-link {
  .boldFont();
  color: @black-color;
  font-size: 14px;
  line-height: 60px;
  padding-left: 40px;
  background: url(../images/backarrow_icon.svg) left center no-repeat;
  background-size: 30px;

  &:hover {
    color: @black-color;
  }
}

.back-btn {
  cursor: pointer;
  .boldFont();
  color: @black-color;
  font-size: 14px;
  line-height: 40px;
  padding-left: 40px;
  background: url(../images/backarrow_icon.svg) left center no-repeat;
  background-size: 30px;
  border: none;

  &:hover {
    color: @black-color;
  }

  &:focus {
    outline: none;
  }
}

.next-btn {
  cursor: pointer;
  position: relative;
  .boldFont();
  color: @black-color;
  font-size: 14px;
  line-height: 40px;
  padding-right: 50px;
  border: none;

  &:hover {
    color: @black-color;
  }

  &:focus {
    outline: none;
  }

  &:after {
    content: "";
    background: url(../images/backarrow_icon.svg) center no-repeat;
    background-size: cover;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    width: 40px;
  }
}

.btn--spaced-right {
  margin-right: 30px;
}

.btn--style-clean {
  background: inherit;

  &:after {
    background-size: 28px;
  }
}

.container-fluid {
  padding: 0;

  @media only screen and (min-width: 320px) {
    overflow-x: hidden;
  }

  @media only screen and (min-width: 768px) {
    overflow: initial;
  }
}

.popup {
  cursor: default;
  position: fixed;
  width: 30%;
  padding: 20px;
  background: @white-color;
  border: 1px solid black;
  left: 35%;
  right: 35%;
  top: 5%;
  z-index: 99999;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 5px;

  &.responsive {
    top: 30%;
    @media only screen and (max-width: 1024px) {
      width: 70%;
      left: 0;
      right: 0;
      margin: 0 auto;
    }

  }

  span.back-link {
    margin-right: 15px;
    cursor: pointer;
  }

  .btn {
    margin-left: 15px;
  }
}

.popup-bg {
  cursor: default;
  content: "";
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9998;
  background: rgba(71, 19, 64, 0.3);
}

.top-user {
  cursor: pointer;
  position: relative;
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 0;
  }

  .user-info {
    margin-top: 20px;

    @media (min-width: 768px) {
      margin-top: 0;
    }

    .user-icon {
      display: none;
      width: 32px;
      height: 32px;
      background: url(../images/purple-user.svg);
      background-size: 100%;
      float: left;

      @media (min-width: 320px) {
        display: block;
        margin-right: 10px;
      }

      @media (min-width: 640px) {
        margin: 0;
      }
    }

    .user-name {
      float: left;
      .boldFont();
      font-size: 16px;
      line-height: 32px;
      color: @white-color;
      margin: 0;

      @media (min-width: 640px) {
        margin: 0 15px 0 15px;
      }
    }

    .menu {
      display: none;
      position: absolute;
      top: 100%;
      right: 0;
      z-index: 1000;
      margin: 5px 0;
      background-color: @white-color;
      box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.8);
      border-radius: 5px;
      width: 150px;
      text-align: center;

      .menu-item {
        .boldFont();
        display: block;
        font-size: 16px;
        line-height: 32px;
        padding: 2px 20px;
        color: @purple-color;
        border-bottom: 1px solid #b7b7b7;

        &:hover {
          text-decoration: none;
          color: #fff;
          background-color: @purple-color;
        }

        &:last-child {
          border-bottom: transparent;
        }
      }
    }

    &.active {
      .menu {
        display: block;
      }
    }
  }
}

.navigation-page-top {
  background-color: @white-color;
  min-height: 60px;
  margin: 0;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);

  .container {
    height: 100%;
    padding: 0;

    .col-sm-3,
    .col-md-3 {
      padding: 0;
    }

    .title-container {
      text-align: center;

      &.col-md-8 {
        text-align: left;
      }

      .nav-title {
        .boldFont();
        font-size: 24px;
        color: #212121;
        line-height: 60px;
      }
    }

    .button-container {
      text-align: right;
      height: 100%;

      .green-button,
      .green-link {
        margin-top: 0;

        @media only screen and (min-width: 768px) {
          margin-top: 10px;
        }
      }

      .green-link__internment-form {
        @media only screen and (min-width: 768px) {
          margin-top: 5px;
        }
      }
    }
  }
}

// Listing actions container start

.actions-container {
  padding: 0 15px 0 0;
  box-sizing: border-box;
  height: 100%;
  text-align: right;
  position: relative;

  .actions-button {
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
    height: 26px;
    background: url(../images/more-icon.svg) top no-repeat;
    background-size: 25px;
    width: 10px;
    margin: 0 auto;
    position: relative;
    float: right;
    /*top: 50%;
    transform: translate(0, -50%);*/
  }

  .menu {
    display: none;
    position: absolute;
    top: 31px;
    right: 0;
    z-index: 1000;
    margin: 5px 0;
    background-color: #fff;
    box-shadow: 0 1px 2px 2px rgba(0, 0, 0, 0.8);
    border-radius: 5px;
    width: 185px;
    text-align: center;

    .menu-item {
      .boldFont();
      cursor: pointer;
      display: block;
      font-size: 16px;
      line-height: 32px;
      padding: 2px 20px;
      color: @purple-color;
      border-bottom: 1px solid #b7b7b7;

      &:hover {
        text-decoration: none;
        color: #fff;
        background-color: @purple-color;
      }

      &:last-child {
        border-bottom: transparent;
      }
    }
  }
}

// Listing actions container end

.message-container {
  position: absolute;
  right: 20px;
  bottom: 100px;
  color: @white-color;
  text-align: center;
  padding: 20px;
  width: auto;

  &.error {
    background-color: #a94442;
  }

  &.success {
    background-color: #5cb85c;
  }

  i {
    position: absolute;
    top: 5px;
    right: 5px;
    cursor: pointer;
  }
}

/**
    LOADER STYLES
 */
.cssload-jumping-container {
  position: fixed;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1001;
  background: rgba(71, 19, 64, 0.2);
}

.cssload-jumping {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 71px;
  top: 50%;
}

.cssload-jumping,
.cssload-jumping * {
  box-sizing: border-box;
}

.cssload-jumping span {
  display: inline-block;
  height: 10px;
  width: 10px;
  background: rgba(71, 19, 64, 0.52);
  border-radius: 325px;
  background-clip: padding-box;
  -o-background-clip: padding-box;
  -ms-background-clip: padding-box;
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding-box;
}

.cssload-jumping span:nth-child(1) {
  animation: scale 2.05s 0.21s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-animation: scale 2.05s 0.21s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-animation: scale 2.05s 0.21s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -webkit-animation: scale 2.05s 0.21s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-animation: scale 2.05s 0.21s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.cssload-jumping span:nth-child(2) {
  animation: scale 2.05s 0.41s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-animation: scale 2.05s 0.41s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-animation: scale 2.05s 0.41s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -webkit-animation: scale 2.05s 0.41s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-animation: scale 2.05s 0.41s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.cssload-jumping span:nth-child(3) {
  animation: scale 2.05s 0.62s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-animation: scale 2.05s 0.62s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-animation: scale 2.05s 0.62s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -webkit-animation: scale 2.05s 0.62s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-animation: scale 2.05s 0.62s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.cssload-jumping span:nth-child(4) {
  animation: scale 2.05s 0.82s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-animation: scale 2.05s 0.82s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-animation: scale 2.05s 0.82s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -webkit-animation: scale 2.05s 0.82s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-animation: scale 2.05s 0.82s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.cssload-jumping span:nth-child(5) {
  animation: scale 2.05s 1.03s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -o-animation: scale 2.05s 1.03s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -ms-animation: scale 2.05s 1.03s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -webkit-animation: scale 2.05s 1.03s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
  -moz-animation: scale 2.05s 1.03s infinite
    cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  25% {
    transform: scale(0.9, 0.9);
    background: rgb(71, 19, 64);
  }
  50% {
    transform: scale(1, 1);
    margin: 0 2px;
    background: rgba(71, 19, 64, 0.45);
  }
  100% {
    transform: scale(0);
  }
}

@-o-keyframes scale {
  0% {
    -o-transform: scale(0);
  }
  25% {
    -o-transform: scale(0.9, 0.9);
    background: rgb(71, 19, 64);
  }
  50% {
    -o-transform: scale(1, 1);
    margin: 0 2px;
    background: rgba(71, 19, 64, 0.45);
  }
  100% {
    -o-transform: scale(0);
  }
}

@-ms-keyframes scale {
  0% {
    -ms-transform: scale(0);
  }
  25% {
    -ms-transform: scale(0.9, 0.9);
    background: rgb(71, 19, 64);
  }
  50% {
    -ms-transform: scale(1, 1);
    margin: 0 2px;
    background: rgba(71, 19, 64, 0.45);
  }
  100% {
    -ms-transform: scale(0);
  }
}

@-webkit-keyframes scale {
  0% {
    -webkit-transform: scale(0);
  }
  25% {
    -webkit-transform: scale(0.9, 0.9);
    background: rgb(71, 19, 64);
  }
  50% {
    -webkit-transform: scale(1, 1);
    margin: 0 2px;
    background: rgba(71, 19, 64, 0.45);
  }
  100% {
    -webkit-transform: scale(0);
  }
}

@-moz-keyframes scale {
  0% {
    -moz-transform: scale(0);
  }
  25% {
    -moz-transform: scale(0.9, 0.9);
    background: rgb(71, 19, 64);
  }
  50% {
    -moz-transform: scale(1, 1);
    margin: 0 2px;
    background: rgba(71, 19, 64, 0.45);
  }
  100% {
    -moz-transform: scale(0);
  }
}

/**
    /LOADER STYLES
 */

@import (reference) "vars";

.login-container {
  // padding: 95px 0 0 0;
  margin: 0 auto;
  ul {
    li {
      list-style: none;
    }
  }
  .logo-container {
    a {
      margin: 0 auto 10px auto;
      display: block;
      width: 150px;
      height: 150px;
      background: url(../images/Logo\ HeROI.png) no-repeat;
      background-size: cover;
      outline: none;
    }
  }
  p {
    text-align: center;
    font-size: 16px;
    color: #212121;
    margin: 0 auto 20px auto;
    .regularFont();
  }
  .login-form {
    form {
      background: @white-color;
      border-radius: 5px;
      padding: 28px 28px 28px 20px;
      box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
      width: 315px;
      margin: 0 auto;
      box-sizing: border-box;
      input {
        height: 35px;
        line-height: 36px;
        padding: 6px 12px 6px 35px;
        &[name="email"] {
          background: url(../images/mail.svg) 10px center no-repeat;
        }
        &[name="password"] {
          background: url(../images/login-password-icon.svg) 10px center
            no-repeat;
        }
        &[name="email"],
        &[name="password"] {
          background-size: 6%;
        }
      }
      button {
        background: #471340;
        color: @white-color;
        font-weight: bold;
        font-size: 16px;
        width: 100%;
        border: 0;
        padding: 15px;
        box-sizing: border-box;
        outline: none;
        .boldFont();
      }
      .forgot-password {
        margin: 30px 0 0 0;
        text-align: center;
        font-size: 12px;
        color: #212121;
        opacity: 0.75;
        .regularFont();
        a {
          font-weight: bold;
          text-transform: uppercase;
          margin: 0 0 0 5px;
          color: #212121;
          opacity: 1;
          outline: none;
          .boldFont();
          &:visited,
          &:focus,
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
  .copyright {
    margin-top: 20px;
    p {
      font-size: 12px;
      .openRegularFont();
      &:first-of-type {
        margin: 0;
        opacity: 0.5;
      }
    }
    .footer-links {
      margin: 15px 0 0 0;
      padding: 0 0 0 5px;
      color: #5c1d4e;
      .openBoldFont();
      &:first-of-type {
        padding: 0 5px 0 0;
        border-right: 2px solid #5c1d4e;
      }
    }
  }
}

@import (reference) 'vars';

.new-patient-form {
    .popup {
        cursor: default;
        position: fixed;
        width: 30%;
        padding: 20px;
        background: @white-color;
        left: 35%;
        right: 35%;
        top: 30%;
        z-index: 99999;
        box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        span.back-link {
            margin-right: 15px;
            cursor: pointer;
        }
        .btn {
            margin-left: 15px;
        }
    }
    .popup-bg {
        cursor: default;
        content: '';
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 9998;
        background: rgba(71, 19, 64, 0.30);
    }
}
